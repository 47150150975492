import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

import Landing from 'components/Landing';
import IntroImage from 'images/landing/sample.png';

const LandingsPage: React.FC = () => (
  <Landing.Layout>
    <Helmet>
      <title>Albums | Travel</title>
      <meta name="og:title" content="Landing" />
      <meta name="twitter:title" content="Landing" />
    </Helmet>

    <Landing.IntroContainer>
      <Landing.IntroContent>
        <Landing.IntroTitle>
          <FormattedMessage id="components.landing.travel.title" />
        </Landing.IntroTitle>
        <Landing.IntroDescription>
          <FormattedMessage id="components.landing.travel.description" />
        </Landing.IntroDescription>
        <Landing.IntroButtons />
      </Landing.IntroContent>
      <Landing.IntroImage src={IntroImage} />
      <Landing.IntroScroll />
    </Landing.IntroContainer>

    <Landing.PublicSections />
  </Landing.Layout>
);

export default LandingsPage;
